export default class CirclePercantage {
    constructor($circle) {
        this.$circle = $circle;
        this.$circle_svg = this.$circle.querySelector('[circle-svg]');
        this.$percantage = this.$circle.querySelector('[percantage]');
    }

    init() {
        this.handler();
    }

    handler(e) {
        let val = parseInt(this.$percantage.innerHTML);
        const $circle = this.$circle_svg.querySelector('circle');

        if (isNaN(val)) {
            val = 100;
        } else {
            const r = $circle.getAttribute('r');
            const c = Math.PI * (r * 2);

            if (val < 0) {
                val = 0;
            }
            if (val > 100) {
                val = 100;
            }

            const pct = ((100 - val) / 100) * c;
            $circle.style.strokeDashoffset = pct;
        }
    }
}

import axios from 'axios';

export default class Feedback {
    constructor($feedback) {
        this.$feedback = $feedback;
        this.$feedbackContainer = this.$feedback.querySelector('[feedback-container]');
        this.$feedbackUndoContainer = this.$feedback.querySelector('[undo-feedback-container]');
        this.$feedbackButtons = this.$feedbackContainer.querySelectorAll('[feedback-button]');
        this.$activeButton = this.$feedbackContainer.querySelector('.active');
        this.$undoFeedbackButtons = this.$feedbackUndoContainer.querySelector('[undo-feedback-button]');
    }

    init() {
        [...this.$feedbackButtons].forEach(el => el.addEventListener('click', () => this.handler(el)));
        this.$undoFeedbackButtons.addEventListener('click', () => this.undoHandler());
    }

    showSuccesMessage() {
        this.$feedbackContainer.style.display = 'none';
        this.$feedbackUndoContainer.style.display = 'flex';
    }

    hideSuccesMessage() {
        this.$feedbackContainer.style.display = 'flex';
        this.$feedbackUndoContainer.style.display = 'none';
    }

    async handlerSubmit(feedbackContainer, el, messageFn) {
        const action = feedbackContainer.getAttribute('action');
        try {
            const { status, statusText } = await axios.get(
                `${action}${el.value}&post-id=${el.getAttribute('data-post-id')}`
            );

            if (el.classList.contains('active')) {
                el.classList.remove('active');
            } else {
                el.classList.add('active');
            }
            if (status === 200 || statusText.toLowerCase() === 'ok') {
                messageFn();
            }
        } catch (error) {
            // this.formData = null;
        }
    }

    handler(el) {
        this.handlerSubmit(this.$feedbackContainer, el, this.showSuccesMessage());
    }

    undoHandler() {
        this.$activeButton = this.$feedbackContainer.querySelector('.active');
        this.handlerSubmit(this.$feedbackUndoContainer, this.$activeButton, this.hideSuccesMessage());
    }
}
